(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.blockFunctions = {

        setup: function(){
            this.slick();
            this.productImages();
            this.gallery();
            this.faqs();
            this.ajax();
            this.testimonials();
            this.testimonialLogos();
            // this.matchPostImageHeight();
        },

        slick: function(){
            var slick = $('.j-slick');
            if(slick.length > 0){
                slick.slick();
            }
        },
        gallery: function(){
            var gallery = $('.j-gallery');
            if(gallery.length > 0){
                gallery.slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: true,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                adaptiveHeight: true,
                            }
                        },
                    ]
                });
            }
        },
        testimonials: function(){
            var testimonials = $('.j-testimonials');
            if(testimonials.length > 0){
                testimonials.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true,
                });
            }
        },
        productImages: function(){
            var products = $('.j-product-carousel');
            if(products.length > 0){
                products.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    fade: true,
                    dots: true,
                    appendDots: $('.o-product-header--thumbnails-image'),
                    customPaging: function(slider, i) { 
                        // this example would render "tabs" with titles
                        return '<div class="tab"><img class="product-carousel--image" src="' + $(slider.$slides[i]).find('.o-product-header--full-img').data('thumbnail-url') + '"></div>';
                    },
                });
            }
        },

        
        testimonialLogos: function(){
            var testimonials = $('.j-testimonial-logos');
            if(testimonials.length > 0){
                testimonials.slick({
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    arrows: true,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 900,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                    ]
                });
            }
        },

        matchPostImageHeight: function(){
            var _this = this;
            _this.matchHeights( '.j-posts--posts', '.g-index-item--image-container' );
            $(window).on('resize', function(){
                _this.matchHeights( '.j-posts--posts', '.g-index-item--image-container' );
            });
        },

        matchHeights: function( parentClass, syncClass ){
            var _this = this;
            var syncedItems = []; // Array to hold the synced classes 
            var parents = $(parentClass); // Container holding items to sync
            if(parents.length > 0 ){ // If there was a container found
                parents.each(function(){
                    var parent = $(this);
                    parent.find(syncClass).css('min-height','0');
                    parent.find(syncClass).each(function(){ // Loop through each of the 'syncClass' items in the container
                        var classes = '.' + $(this).attr('class').replace(' ', '.'); // Pull the full class list
                        if(!syncedItems.includes(classes)){  // Check if the array is already storing this class list 
                            // If not, process these items
                            var height = 0; // height of tallest block (0 by default)
                            parent.find(syncClass).each(function(){ // Loop through each of the 'syncClass' items in the parent
                                var _this = $(this); // reference for $(his);
                                if( _this.outerHeight() > height ) { // If the current element height is taller than the stored 
                                    height = _this.outerHeight();      // replace stored value with current
                                }
                            });
                            parent.find(syncClass).css('min-height',height + 'px' ); // Set all the elements to use min-height matching the tallest item
                            // store the class list so we don't process these items again
                            syncedItems.push(classes);
                        }
                    });
                });
            }
        },


        faqs: function(){
            var faqs = $('.j-faqs');
            if(faqs.length > 0 ){
                faqs.on('click','.j-faqs--toggle', function(){
                    $(this).parents('.j-faqs--single').toggleClass('faq-is-active').find('.j-faqs--answer').slideToggle();
                });
                faqs.on('click','.j-toggle--button', function(){
                    $('.faq-is-active').removeClass('faq-is-active').find('.j-faqs--answer').hide();
                });
            }
        },
        updateURL: function(url){
            history.pushState({}, url, url);
        },
        ajax: function(){
            var ajax = $('.j-posts');
            if(ajax.length > 0 ){   
                ajax.on('click','.j-posts--filter', function(e){
                    e.preventDefault();
                    window.blockFunctions.updateAjax( $(this).parents('.j-posts'), $(this).attr('href') );
                }); 
                ajax.on('change','.j-posts--select', function(e){
                    e.preventDefault();
                    window.blockFunctions.updateAjax( $(this).parents('.j-posts'), $(this).val() );
                }); 
                ajax.on('click','.page-numbers', function(e){
                    e.preventDefault();
                    window.blockFunctions.updateAjax( $(this).parents('.j-posts'), $(this).attr('href') );
                }); 
                ajax.on('click','.j-posts--load-more', function(e){
                    e.preventDefault();
                    var block = $(this).parents('.j-posts');
                    var tag = block.data('tag');
                    var url = $(this).attr('href'); 
                    window.blockFunctions.fetch( url, function(results){
                        block.find('.j-posts--filters').html( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--filters').html() );    
                        block.find('.j-posts--posts').append( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--posts').html() );    
                        block.find('.j-posts--pagination').html( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--pagination').html() );    
                    });
                });    
                ajax.on('submit','.search-form', function(e){
                    e.preventDefault();
                    window.blockFunctions.updateAjax( $(this).parents('.j-posts'), $(this).attr('action') + "?" + $(this).serialize() );
                });   

                
            }
        },
        updateAjax: function( block, url){
            var tag = block.data('tag');
            window.blockFunctions.fetch( url, function(results){
                block.find('.j-posts--filters').html( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--filters').html() );    
                block.find('.j-posts--posts').html( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--posts').html() );    
                block.find('.j-posts--pagination').html( results.find('.j-posts[data-tag="' + tag + '"]').find('.j-posts--pagination').html() );    
                if(block.data('update-url')){
                    window.blockFunctions.updateURL(url);
                }
            });
        },

         // Fetch a page
        fetch: function( url, callback){

            var container = $('body');

            if(! container.hasClass('j-ajax__loading')){
                // Non ajax page reload
                container.addClass('j-ajax__loading');
                container.trigger('ajaxLoadBegin');

                // ajax request a page
                this.ajax = jQuery.ajax({
                    dataType: 'text',
                    url: url,

                    success: function (rawhtml) {
                        // get results
                        container.removeClass('j-ajax__loading');
                        container.trigger('ajaxLoadComplete');

                        // run callback function
                        callback( $(rawhtml) );
                    },

                    error: function(){
                        container.trigger('ajaxLoadFail');
                    },
                });
            }
        },
    };
}(window.jQuery, window, document)); // End of jQuery wrapper