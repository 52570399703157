/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				window.HeaderFunctions.setup();
				window.blockFunctions.setup();



				AOS.init({
					once: true,
				});


				$('.height-change-listener').each( function(){
					$(this.contentWindow).resize(function() {
						AOS.refresh();

					} );
				});
				$('.j-age-check--enter').on('click',function(){
					createCookie('age_verified', 'true');
					$('body').addClass('is-of-age').removeClass('not-of-age');
				})
				if (readCookie('age_verified') === 'true') {
					$('body').addClass('is-of-age');
				}else{
					$('body').addClass('not-of-age');
				}


				function createCookie(name, value, days) {
					var expires = "";
					if (days) {
					  var date = new Date();
					  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
					  expires = "; expires=" + date.toUTCString();
					}
					document.cookie = name + "=" + value + expires + "; path=/";
				}

				function readCookie(name) {
					var nameEQ = name + "=";
					var ca = document.cookie.split(';');
					for (var i = 0; i < ca.length; i++) {
					  var c = ca[i];
					  while (c.charAt(0) == ' ') c = c.substring(1, c.length);
					  if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
					}
					return null;
				}

				function eraseCookie(name) {
					createCookie(name, "", -1);
				}

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
